import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "NTVlYzZmNWU4MWM0MTU5ZDQ2YWE3NDE2ODhmMDRmMTY=",
	"Tenant-Access-Key": "ROwzgGs2JE4zew",
};

export const api = axios.create({
	baseURL: "https://immersive-game-india.confirmu.com",
	headers: {
		"Tenant-Access-Token": "NTVlYzZmNWU4MWM0MTU5ZDQ2YWE3NDE2ODhmMDRmMTY=",
		"Tenant-Access-Key": "ROwzgGs2JE4zew",
	},
});

export const BASE_URL = "https://immersive-game-india.confirmu.com";
